import React from 'react';

export default function Sandbox() {
  return (
    <div>
        <div className={'flex flex-row flex-wrap'}>
            <div className={'bg-indigo-500 w-2/3 p-5'}>
                <h1>Hello World</h1>
            </div>
            <div className={'bg-blue-100 w-1/3 p-5'}>
                <h1>Hello World 2</h1>
            </div>
        </div>
    </div>

  );
}

