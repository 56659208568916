import { useEffect } from 'react';

const CrispChat = () => {
  useEffect(() => {
    // Check if the user agent is ReactSnap, if it is, don't initialize Crisp
    if (/ReactSnap/i.test(window.navigator.userAgent)) {
      return;
    }

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "0f530ef3-6111-4695-a121-66807da452ec";

    const d = document;
    const s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  }, []);

  return null;
};

export default CrispChat;