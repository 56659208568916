import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import SandboxPage from "./pages/Sandbox.js";
import CrispChat from "./components/CrispChat";

function App() {
  return (
      <div>
          <CrispChat />
          <Routes>
              <Route path="/" element={<IndexPage />} />
              <Route path="/sandbox" element={<SandboxPage />} />

          </Routes>
      </div>
  );
}

export default App;
