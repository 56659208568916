import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {IonIcon} from "@ionic/react";
import { Link } from 'react-scroll';
import {
  basket,
  briefcase,
  business,
  cafe,
  flash,
  grid,
  logoEuro,
  medical,
  pin,
  pricetag,
  shareSocial,
  train,
  create,
  mail,
  people,
  list,
  reader,
  cash,
  folder,
  home,
  person,
  chatbubbles,
  at,
  shuffle,
  radio,
  arrowForward
} from "ionicons/icons";
import {AnimatePresence, motion, useScroll, useTransform} from "framer-motion";

const meta = {
  title: 'Prospectis, la solution de prospection immobilière tout-en-un',
  meta: [],
  link: [],
  style: [],
  script: [],
};

function FeatureCRM() {
  const items = [{'icon':create, 'label':'Notes', colorClass: 'bg-gradient-to-br from-yellow-300 to-yellow-400'}, {'icon':mail, 'label':'Boites', colorClass: 'bg-gradient-to-br from-fuschia-300 to-fuschia-400'}, {'icon':people, 'label':'Personnes', colorClass: 'bg-gradient-to-br from-pink-400 to-pink-500'}, {'icon':list, 'label':'Rappels', colorClass: 'bg-gradient-to-br from-indigo-400 to-indigo-500'}, {'icon':reader, 'label':'Estimations', colorClass: 'bg-gradient-to-br from-emerald-400 to-emerald-500'}, {'icon':cash, 'label':'Ventes', colorClass: 'bg-gradient-to-br from-lime-400 to-lime-500'}, {'icon':home, 'label':'Propriétés', colorClass: 'bg-gradient-to-br from-orange-400 to-orange-500'}, {'icon':folder, 'label':'Fichiers', colorClass: 'bg-gradient-to-br from-amber-400 to-amber-500'}];

  return (
      <div className={"flex flex-col"}>
        <div className="text-center">
          <h3 className="mb-3 text-6xl font-bold leading-normal">Gestion de la relation client</h3>
          <p className="text-lg">Centralisez toutes les informations</p>
        </div>
        <div className="flex justify-center items-center">
          <div>
            <div className="my-40 absolute w-64 h-64 bg-gray-400 rounded-full opacity-25 transform -translate-x-1/2 -translate-y-1/2 hover:scale-105 transition-transform"></div>
            <div className="my-40 absolute w-56 h-56 bg-white rounded-full opacity-100 transform -translate-x-1/2 -translate-y-1/2 hover:scale-105 transition-transform"></div>
            <IonIcon icon={person} size={"large"} color={"medium"} className={"my-40 absolute transform -translate-x-1/2 -translate-y-1/2 p-4 rounded-full bg-white bg-opacity-60"}/>
          </div>
          <div className="relative">
            {items.map((item, index) => (
                <div className={"my-40 absolute w-12 h-12 rounded-full -translate-x-1/2 -translate-y-1/2"}>
                  <div
                      key={index}
                      className="flex flex-col items-center  transform"
                      style={{transform: `rotate(${index * (360 / items.length)}deg) translate(8rem) rotate(-${index * (360 / items.length)}deg)`}}
                  >
                    <IonIcon color={"light"} icon={item.icon} size={"small"} className={"p-3 " + item.colorClass + " rounded-full hover:scale-105 transition-all"}/>
                    {item.label}
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
  );
}

function FeatureChannels() {
  return (
      <div className={"flex flex-grow flex-col"}>
        <div className="text-center">
          <h3 className='mb-3 text-6xl font-bold font-heading leading-normal'>Canaux de diffusion</h3>
          <p className={"text-lg"}>Communiquez avec vos prospects</p>
        </div>
        <div className={"flex flex-row justify-center flex-1 items-center"}>
          <div className={"absolute opacity-10"}>
            <IonIcon icon={radio} style={{"fontSize": "19em"}} className={"-mt-5"}></IonIcon>
          </div>
          <div className={"flex items-center flex-col"}>
            <IonIcon
                className="m-1 p-4 bg-gradient-to-br from-green-300 to-green-500 rounded-br-2xl rounded-bl-2xl rounded-se-lg rounded-ss-5xl"
                icon={chatbubbles} size={"large"} color={"light"}></IonIcon>
            <p>SMS</p>
          </div>
          <div className={"flex items-center flex-col"}>
            <IonIcon className="m-1 p-4 bg-gradient-to-br from-blue-300 to-blue-500 rounded-2xl"
                     icon={at} size={"large"} color={"light"}></IonIcon>
            <p>Email</p>
          </div>
          <div className={"flex items-center flex-col"}>
            <IonIcon
                className="m-1 p-4 bg-gradient-to-br from-orange-300 to-orange-500 rounded-br-2xl rounded-bl-2xl rounded-se-5xl rounded-ss-lg"
                icon={shuffle} size={"large"} color={"light"}></IonIcon>
            <p>Ségments</p>
          </div>
        </div>
      </div>
  );

}

export function Menu() {
  const { scrollY } = useScroll();
  const scale = useTransform(scrollY, [0, 150], [0.9, 0.75]);

  const navHeight = useTransform(scrollY, [0, 150], ['8rem', '4.5rem']);
  const border = useTransform(scrollY, [150, 200], ['none', '2px solid #e5e5e5']);

  return (
      <motion.nav
          className='flex flex-row w-full items-center bg-white bg-opacity-80 backdrop-blur-md z-50 fixed top-0 '
          style={{ height: navHeight, borderBottom: border }}
      >
        <div className='container mx-auto overflow-hidden'>
          <div className='relative z-20 flex items-center px-4 justify-center sm:justify-between'>
            <div className='flex flex-wrap items-center'>

              <motion.a
                  style={{scale}}
              >
                <Link to='hero' smooth={true} duration={500} offset={-80}>
                  <img
                      className='transition ease-in-out duration-300 object-scale-down'
                      src={'images/logo.png'}
                      alt=''
                      style={{height: '60px', width: 'auto', scale}}
                  />
                </Link>
              </motion.a>

            </div>
              <div className={"flex-row gap-4 hidden sm:flex"}>
                <motion.ul className='flex flex-row items-center space-x-5 transition-all'>
                  <li>
                    <Link to='features' smooth={true} duration={500}
                          className='text-gray-500 hover:text-gray-700'>Solutions</Link>
                  </li>
                  <li>
                    <Link to='pricing' smooth={true} duration={500} offset={-100}
                          className='text-gray-500 hover:text-gray-700'>Tarifs</Link>
                  </li>
                  <li>
                    <a target={"_blank"} href="https://app.prospectis.immo" className='text-gray-500 hover:text-gray-700'>Connexion</a>
                  </li>
                </motion.ul>
                <div className='flex-grow sm:flex-grow-0'>
                  <motion.div
                      style={{scale}}
                      className="hover:scale-110 transition-all"
                  >
                    <a className="bg-fuschia-500 py-3 px-5 rounded-full text-white text-md font-medium hover:bg-fuschia-400 flex items-center"
                       href={'https://manager.prospectis.immo/onboarding/signup'} target={'_blank'}>
                      S'inscrire
                      <IonIcon icon={arrowForward} color={"light"} className={"pl-1.5"}></IonIcon>
                    </a>
                  </motion.div>
                </div>
              </div>

          </div>
        </div>
      </motion.nav>
);
}

export function Hero() {
  const {scrollY} = useScroll();
  const scale = useTransform(scrollY, [350, 550], [1, 0.85]);


  return (
      <motion.div
          className={"bg-gray-50"}
          initial="hidden"
          animate="show"
      >
        <AnimatePresence>
          <motion.div className={"pt-32 sm:pt-60 flex flex-col items-center p-5 pb-14 bg-white rounded-5xl bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] "} style={{scale}}>
            <motion.div className={"flex flex-col items-center"} variants={{
              hidden: {opacity: 0},
              show: {opacity: 1, transition: {delay: 0.2, duration: 1}}
            }}>

              <h1 className="text-8xl md:text-10xl xl:text-11xl font-bold font-heading text-center leading-none px-2 md:px-40 lg:px-80">
                Votre <mark style={{background: "linear-gradient(to top, #fde047 35%, transparent 35%)"}}>prochain mandat</mark>, vous le trouverez ici
              </h1>
              <h2
                  className="mt-4 text-center text-lg leading-tight md:text-2xl xl:text-3xl"
              >
                Plus de données.
                Plus d'opportunités.
                Plus de mandats.
              </h2>

              <div className={"mt-10 hover:scale-105 transition-all"}>
                <a className={"bg-fuschia-500 py-4 px-6 rounded-full text-white text-lg font-medium hover:bg-fuschia-400"}
                   href={'https://manager.prospectis.immo/onboarding/signup'} target={'_blank'}>Commencer
                  gratuitement</a>
              </div>
            </motion.div>
            <motion.div variants={{
              hidden: {opacity: 0},
              show: {opacity: 1, transition: {delay: 1.5, duration: 1}}
            }}>
              <ClientsLogos/>
            </motion.div>
            <motion.div className={"w-full md:w-1/2 mt-10"} variants={{
              hidden: {opacity: 0},
              show: {opacity: 1, transition: {delay: 2.5, duration: 1}}
            }}>
              <div className={"bg-fuschia-100 bg-opacity-25 border-fuschia-200 border rounded-5xl p-4 drop-shadow-xl"}>
                <video autoPlay loop muted playsInline src={'videos/preview.mp4'} alt=''
                       className={'w-full rounded-5xl '}/>
              </div>

            </motion.div>
          </motion.div>
        </AnimatePresence>
      </motion.div>
  );
}

function FeaturePige() {
  return (
      <div>
        <div className="text-center">
          <h3 className='mb-3 text-6xl font-bold font-heading leading-normal'>Pige immobilière</h3>
          <p className={"leading-none text-lg"}>Détectez les signaux de ventes</p>
          <p className={"text-base"}>Restez informé du marché</p>
        </div>
        <div className={"flex flex-row gap-7 flex-wrap items-center justify-center mt-4 flex-1"}>
          <div className={"flex items-center flex-col hover:scale-105 transition-all"}>
            <IonIcon className="m-1 p-4 bg-gradient-to-br from-green-400 to-lime-500 rounded-2xl"
                     icon={flash} size={"large"} color={"light"}></IonIcon>
            <p>DPE</p>
          </div>
          <div className={"flex items-center flex-col hover:scale-105 transition-all"}>
            <IonIcon className="m-1 p-4 bg-gradient-to-br from-amber-400 to-orange-500 rounded-2xl"
                     icon={pricetag} size={"large"} color={"light"}></IonIcon>
            <p>Annonces</p>
          </div>
          <div className={"flex items-center flex-col hover:scale-105 transition-all"}>
            <IonIcon className="m-1 p-4 bg-gradient-to-br from-teal-400 to-cyan-500 rounded-2xl"
                     icon={shareSocial} size={"large"} color={"light"}></IonIcon>
            <p>Successions</p>
          </div>
          <div className={"flex items-center flex-col hover:scale-105 transition-all"}>
            <IonIcon className="m-1 p-4 bg-gradient-to-br from-yellow-600 to-amber-700 rounded-2xl"
                     icon={grid} size={"large"} color={"light"}></IonIcon>
            <p>Cadastre</p>
          </div>
          <div className={"flex items-center flex-col hover:scale-105 transition-all"}>
            <IonIcon className="m-1 p-4 bg-gradient-to-br from-purple-400 to-violet-500 rounded-2xl"
                     icon={briefcase} size={"large"} color={"light"}></IonIcon>
            <p>Propriétaires</p>
          </div>
          <div className={"flex items-center flex-col hover:scale-105 transition-all"}>
            <IonIcon className="m-1 p-4 bg-gradient-to-br from-blue-400 to-indigo-500 rounded-2xl"
                     icon={business} size={"large"} color={"light"}></IonIcon>
            <p>Copropriétés</p>
          </div>
          <div className={"flex items-center flex-col hover:scale-105 transition-all"}>
            <div
                className={"mt-1 p-3 bg-gradient-to-br from-emerald-400 to-green-500 rounded-2xl h-16 w-16 flex justify-center items-center flex-col"}>
              <div>
                <IonIcon icon={basket} style={{fontSize: 15}} color={"light"}></IonIcon>
                <IonIcon icon={cafe} style={{fontSize: 15}} color={"light"}></IonIcon>
              </div>
              <div>
                <IonIcon icon={medical} style={{fontSize: 15}} color={"light"}></IonIcon>
                <IonIcon icon={train} style={{fontSize: 15}} color={"light"}></IonIcon>
              </div>
            </div>
            <p>Alentours</p>
          </div>
          <div className={"flex items-center flex-col hover:scale-105 transition-all"}>
            <IonIcon className="m-1 p-4 bg-gradient-to-br from-yellow-300 to-amber-400 rounded-2xl"
                     icon={logoEuro} size={"large"} color={"light"}></IonIcon>
            <p>Ventes</p>
          </div>
          <div className={"flex items-center flex-col hover:scale-105 transition-all"}>
            <IonIcon className="m-1 p-4 bg-gradient-to-br from-red-400 to-pink-500 rounded-2xl"
                     icon={pin} size={"large"} color={"light"}></IonIcon>
            <p>Adresses</p>
          </div>
        </div>
      </div>
  )
}

export const ReviewCard = ({ review }) => {
  return (
      <div className="max-w-sm bg-gray-50 rounded-4xl overflow-hidden">
        <div className="p-5">
          <div className={"flex flex-row justify-start items-center"}>
            <img
                className="w-16 h-16 rounded-full"
                src={review.photo}
                alt="Avatar"
            />
            <div className={"ml-7 flex flex-col justify-center"}>
              <h5 className="text-gray-900 text-xl font-semibold mb-2 leading-none">{review.name}</h5>
              <p className={"text-gray-400 text-lg leading-none"}>{review.position}</p>
            </div>
          </div>
          <p className="text-gray-700 text-base my-4">
            {review.content}
          </p>
        </div>
      </div>
  );
};

function Reviews() {
  const reviews = [
    {
      name: "André",
      photo: "https://randomuser.me/api/portraits/men/20.jpg",
      position: "Agent immobilier",
      content: "Prospectis a complètement changé ma façon de travailler. Grâce à sa capacité à identifier rapidement les biens correspondant aux critères de mes clients, j'ai pu doubler mon nombre de visites en un mois. Un outil indispensable pour tout agent souhaitant optimiser son temps."
    },
    {
      name: "Thomas",
      photo: "https://randomuser.me/api/portraits/men/2.jpg",
      position: "Agent immobilier",
      content: "J'utilise Prospectis depuis trois mois et je suis impressionné par son interface utilisateur intuitive. La navigation est simple, ce qui me permet de gagner un temps précieux chaque jour."
    },
    {
      name: "Marie",
      photo: "https://randomuser.me/api/portraits/men/22.jpg",
      position: "Agent immobilier",
      content: "Prospectis offre une vue d'ensemble fantastique des tendances du marché, ce qui me permet de conseiller efficacement mes clients. Sa fonction de suivi des prospects est aussi très performante, m'aidant à maintenir le contact avec mes clients potentiels."
    },
    {
      name: "Jean",
      photo: "https://randomuser.me/api/portraits/men/29.jpg",
      position: "Agent immobilier",
      content: "Je suis très satisfait de Prospectis pour la gestion de mes leads. L'outil de segmentation me permet de personnaliser mes approches en fonction du profil de chaque prospect, augmentant ainsi mes taux de conversion."
    },
    {
      name: "Sophie",
      photo: "https://randomuser.me/api/portraits/men/10.jpg",
      position: "Agent immobilier",
      content: "Ce que j'apprécie le plus avec Prospectis, c'est sa rapidité. En quelques clics, je peux accéder à des informations détaillées sur une propriété et préparer mes visites bien à l'avance."
    },
    {
      name: "Marc",
      photo: "https://randomuser.me/api/portraits/men/9.jpg",
      position: "Agent immobilier",
      content: "Prospectis est non seulement utile pour trouver des biens, mais aussi pour établir des connexions avec d'autres agents. Le réseau d'utilisateurs est actif et très supportif."
    },
    {
      name: "Lucie",
      photo: "https://randomuser.me/api/portraits/men/26.jpg",
      position: "Agent immobilier",
      content: "L'utilisation de Prospectis m'a permis de réduire considérablement le temps passé sur des tâches administratives. Sa fonctionnalité de rapport automatique est un véritable atout pour suivre mes performances."
    },
    {
      name: "Alex",
      photo: "https://randomuser.me/api/portraits/men/23.jpg",
      position: "Agent immobilier",
      title: "Essentiel pour le marché actuel",
      content: "Dans un marché aussi compétitif, Prospectis me donne un avantage significatif grâce à ses outils d'analyse de marché avancés. Je peux anticiper les tendances et adapter mes stratégies en conséquence."
    }
  ];

  return (
      <div className={"p-18 pb-0 bg-white flex flex-col items-center"}>
        <div>
          <h2 className='text-8xl md:text-10xl xl:text-12xl font-bold font-heading text-center leading-none p-12'>
            Les pros choisissent Prospectis
          </h2>
          <div>
            <div>
              {/*TODO : Ajouter widgets app store et play store reviews avec les étoiles et un lien qui renvoi vers la page de reviews*/}
            </div>
          </div>
        </div>
        <div className={"overflow-hidden h-[650px]"}>
          <div className="columns-1 md:columns-2 lg:columns-3 gap-6">
            {reviews.map((review, index) => (
                <div className="break-inside-avoid mb-6">
                  <ReviewCard key={index} review={review}/>
                </div>
            ))}
          </div>
        </div>
      </div>
  )
}

function DailyUse() {
  return (
      <div>
        <div className='container px-4 mx-auto'>
          <div
              className={"mb-20 text-8xl md:text-10xl xl:text-12xl font-bold font-heading text-center tracking-px-n leading-none"}>
            <h2 className='text-black'>
              Votre compagnon du quotidien
            </h2>
          </div>
        </div>

        <div className={"grid grid-cols-6 gap-6"}>

          <div
              className='flex flex-col flex-grow rounded-5xl bg-white hover:scale-[1.02] transition duration-300 min-h-[24em] px-6 pb-3 col-span-6 md:col-span-2 xl:col-span-2'>

            <div className="pt-0 max-h-96">
              <img src="images/daily-dpe.png" alt=""
                   className="w-full h-full object-cover object-bottom drop-shadow-lg"/>
            </div>


            <div className="text-center px-8 pt-8 pb-2">
              <h5 className={"uppercase text-green-500 font-extrabold"}>Quotidien</h5>
              <div className={"mb-3"}>
                <h3 className='text-5xl font-bold font-heading leading-normal'>DPE</h3>
                <IonIcon className="m-1 p-3 bg-gradient-to-br from-green-400 to-lime-500 rounded-2xl"
                         icon={flash} size={"large"} color={"light"}></IonIcon>
              </div>

            </div>
          </div>

{/*          <div
              className='flex flex-col flex-grow rounded-5xl bg-white hover:scale-[1.02] transition duration-300 min-h-[24em] px-6 pb-3 col-span-4 md:col-span-2 xl:col-span-1'>

            <div className="pt-0 max-h-96">
              <img src="images/daily-dpe.png" alt=""
                   className="w-full h-full object-cover object-bottom drop-shadow-lg"/>
            </div>


            <div className="text-center px-8 pt-8 pb-2">
              <h5 className={"uppercase text-orange-500 font-extrabold"}>2 fois par jour</h5>
              <div className={"mb-3"}>
                <h3 className='text-5xl font-bold font-heading leading-normal'>Annonces</h3>
                <IonIcon className="m-1 p-3 bg-gradient-to-br from-amber-400 to-orange-500 rounded-2xl"
                         icon={pricetag} size={"large"} color={"light"}></IonIcon>
              </div>

            </div>
          </div>*/}

          <div
              className='flex flex-col flex-grow rounded-5xl bg-white hover:scale-[1.02] transition duration-300 min-h-[24em] px-6 pb-3 col-span-6 md:col-span-2 xl:col-span-2'>

            <div className="pt-0 max-h-96">
              <img src="images/daily-legacy.png" alt=""
                   className="w-full h-full object-cover object-bottom drop-shadow-lg"/>
            </div>


            <div className="text-center px-4 pt-8 pb-2">
              <h5 className={"uppercase text-cyan-500 font-extrabold"}>Mensuel</h5>
              <div className={"mb-3"}>
                <h3 className='text-5xl font-bold font-heading leading-normal'>Successions</h3>
                <IonIcon className="m-1 p-3 bg-gradient-to-br from-teal-400 to-cyan-500 rounded-2xl"
                         icon={shareSocial} size={"large"} color={"light"}></IonIcon>
              </div>
            </div>
          </div>

          <div
              className='flex flex-col flex-grow rounded-5xl bg-white hover:scale-[1.02] transition duration-300 min-h-[24em] px-6 pb-3 col-span-6 md:col-span-2 xl:col-span-2'>

            <div className="pt-0 max-h-96">
              <img src="images/daily-owners.png" alt=""
                   className="w-full h-full object-cover object-bottom drop-shadow-lg"/>
            </div>


            <div className="text-center px-8 pt-8 pb-2">
              <h5 className={"uppercase text-violet-500 font-extrabold"}>SCI et SARL</h5>
              <div className={"mb-3"}>
                <h3 className='text-5xl font-bold font-heading leading-normal'>Propriétaires</h3>
                <IonIcon className="m-1 p-3 bg-gradient-to-br from-purple-400 to-violet-500 rounded-2xl"
                         icon={briefcase} size={"large"} color={"light"}></IonIcon>
              </div>
            </div>
          </div>

        </div>

        <div className={"grid grid-cols-6 gap-6 mt-24"}>

          <div
              className='flex flex-col flex-grow rounded-5xl bg-white hover:scale-[1.02] transition duration-300 min-h-[24em] px-6 pt-3 col-span-6 md:col-span-2'>

            <div className="text-center px-4 pt-8 pb-2">
              <div className={"mb-3"}>
                <h3 className='text-5xl font-bold font-heading leading-normal'>Ajouter des boites</h3>
                <IonIcon icon={mail} size={"large"}
                         className={"text-white p-3 bg-gradient-to-br from-fuschia-300 to-fuschia-400 rounded-full"}/>
              </div>
            </div>
            <div className="pb-0 max-h-96">
              <img src="images/daily-letterbox.png" alt=""
                   className="w-full h-full object-cover object-top "/>
            </div>
          </div>

          <div
              className='flex flex-col flex-grow rounded-5xl bg-white hover:scale-[1.02] transition duration-300 min-h-[24em] px-6 pt-3 col-span-6 md:col-span-2'>

            <div className="text-center px-2 pt-8 pb-2">
              <div className={"mb-3"}>
                <h3 className='text-5xl font-bold font-heading leading-normal'>Trouvez vos cibles</h3>
                <IonIcon icon={people} size={"large"}
                         className={"text-white p-3 bg-gradient-to-br from-pink-400 to-pink-500 rounded-full"}/>
              </div>
            </div>
            <div className="pb-0 max-h-96">
              <img src="images/daily-person.png" alt=""
                   className="w-full h-full object-cover object-top "/>
            </div>
          </div>

          <div
              className='flex flex-col flex-grow rounded-5xl bg-white hover:scale-[1.02] transition duration-300 min-h-[24em] px-6 pt-3 col-span-6 md:col-span-2'>

            <div className="text-center px-8 pt-8 pb-2">
              <div className={"mb-3"}>
                <h3 className='text-5xl font-bold font-heading leading-normal'>Prenez des notes</h3>
                <IonIcon icon={create} size={"large"}
                         className={"text-white p-3 bg-gradient-to-br from-yellow-300 to-yellow-400 rounded-full"}/>
              </div>
            </div>
            <div className="pb-0 max-h-96">
              <img src="images/daily-note.png" alt=""
                   className="w-full h-full object-cover object-top "/>
            </div>
          </div>

        </div>


      </div>
  );
}

function ClientsLogos() {
  const logos = [
    "images/clients/logo-ladresse.png",
    "images/clients/logo-stephane-plaza.png",
    "images/clients/logo-era.png",
    "images/clients/logo-iad.png",
    "images/clients/logo-safti.png",
    "images/clients/logo-origami.png",
    ];

    return (
        <div className="mt-12">
          <div className='container px-4 mx-auto text-center'>
            <div className="mt-7 mb-2 text font-bold font-heading text-center tracking-px-n leading-none">
              <h2 className='text-gray-500 uppercase'>
                Ils sont déja + de 1300 à l'utiliser
              </h2>
            </div>
            <div
                className="flex flex-wrap justify-center space-x-4 sm:space-x-6 md:space-x-14 overflow-hidden px-2 sm:px-8">
              {logos.map((logo, index) => (
                  <div className="w-auto py-3">
                    <img key={index} src={logo} alt="" className="h-8 grayscale object-scale-down mx-auto"/>
                  </div>
              ))}
            </div>
          </div>
        </div>


    );

}

export default function Index() {
  return (
      <React.Fragment>
        <HelmetProvider>
          <Helmet {...meta}></Helmet>
        </HelmetProvider>
        <>

          <section id={"hero"}>

            <Menu/>
            <Hero/>
          </section>

          <section className='mt-90 pt-24 pb-60 bg-gray-50 overflow-hidden' id={"features"}>
            <div className='container px-4 mx-auto'>
              <div
                  className={"mb-20 text-8xl md:text-10xl xl:text-12xl font-bold font-heading text-center tracking-px-n leading-none"}>

                <h2 className='text-black'>
                  Nos solutions de prospection
                </h2>
              </div>


              <div className='grid grid-cols-5 gap-6'>
                <div
                    className='flex flex-col flex-grow rounded-5xl bg-white hover:scale-[1.02] transition duration-300 p-10 min-h-[24em] col-span-5'>
                  <FeaturePige/>
                </div>


                <div
                    className="flex flex-col relative rounded-5xl bg-white hover:scale-[1.02] transition duration-300 p-10 col-span-5 lg:col-span-3 min-h-[35em]">
                  <FeatureCRM/>
                </div>

                <div
                    className='flex flex-col rounded-5xl bg-white hover:scale-[1.02] transition duration-300 p-10 col-span-5 lg:col-span-2 min-h-[28em]'>
                  <FeatureChannels/>
                </div>
              </div>
            </div>
          </section>

          {/*<section className={"shadow-inner"}>
            <Reviews/>
          </section>*/}

          <section className='py-36 !pt-0 bg-gray-50 '>
            <div className='container px-4 py-10 !pt-0 mx-auto'>
              <DailyUse/>
            </div>
          </section>

          <section className='flex flex-col items-center my-24 px-5 pb-20' id={"pricing"}>
            <div className='container mx-auto'>
              <div
                  className={"text-3xl md:text-6xl xl:text-9xl font-bold font-heading text-center tracking-px-n leading-none"}>

                <h2 className='text-black'>
                  Prêt à optimiser votre prospection ?
                </h2>
              </div>
            </div>
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-5 xl:mx-52"}>
              <div
                  className={'flex mt-16 col-span-1 rounded-4xl bg-sky-500 px-18 py-10 text-white flex-col items-center gap-1 hover:scale-[1.02] transition-all duration-500 '}>
                <img
                    src={'images/logo-icon.png'}
                    alt='Logo de Prospectis'
                    className={"max-h-20 hover:rotate-90  transition-all duration-1000 hover:scale-125"}
                />
                <p className={"text-3xl font-semibold"}>Prospectis Pro</p>
                <p className={"text-center"}>Un seul outil pour organiser toute votre prospection</p>
                <div className="space-x-2 text-center mt-3">
                  <span className="text-lg font-medium text-white line-through">19,90 € <span
                      className="text-sm"></span></span>
                  <span
                      className="order-first inline-block px-2.5 py-0.5 text-xs font-semibold tracking-wider text-white uppercase bg-black rounded-full lg:order-none bg-opacity-50">Offre -25%</span>
                  <br/>
                  <span className="text-4xl font-bold text-white">14,90 € <span className="text-sm">/ mois</span></span>
                </div>
                <hr className={"h-1 bg-white w-full opacity-30 rounded-full my-5"}/>
                <p className={"text-center text-sm"}>Sans engagement de durée <br/>Essai gratuit de 15 jours, annulable
                  à tout moment </p>
                <a
                    className="bg-white py-3 px-5 mt-10 rounded-full text-sky-600 text-md font-medium hover:bg-opacity-100 transition-all"
                    href={'https://manager.prospectis.immo/onboarding/signup'} target={'_blank'}>Essayer gratuitement
                </a>
              </div>

              <div
                  className={'mt-16 col-span-1 rounded-4xl bg-fuschia-500 px-18 py-10 text-white flex flex-col items-center gap-1 hover:scale-[1.02] transition-all duration-500'}>
                <div className={"flex flex-row gap-3"}>
                  <img src={"images/julien.jpeg"} alt={"Julien de Prospectis"}
                       className={"w-20 h-20 rounded-full border-2"}/>
                  <img src={"images/noe.jpeg"} className={"w-20 h-20 rounded-full border-2"}/>

                </div>

                <p className={"text-3xl font-semibold"}>Rencontrez-nous</p>
                <p className={"text-center mb-3"}>
                  Echangeons sur ce que Prospectis peut vous apporter
                  </p>
                <p className={"text-center font-medium"}>
                  Démonstration personnalisée sur votre secteur de notre solution de prospection
                </p>
                <hr className={"h-1 bg-white w-full opacity-30 rounded-full my-5"}/>
                <p className={"text-center text-sm"}>15-20 minutes à votre convenance <br/> Démonstration gratuite sans aucun engagement</p>
                <a
                    className="bg-white py-3 px-5 mt-10 rounded-full text-fuschia-500 text-md font-medium hover:bg-opacity-90"
                    href={'https://calendly.com/julien-genlyx/decouverte-prospectis'} target={'_blank'}>Planifiez un
                  rendez-vous
                </a>
              </div>
            </div>

          </section>

          {/* Footer */}
          <section className='pt-24 bg-gray-50 overflow-hidden'>
            <div className='container px-4 mx-auto'>
              <div className='pb-20 border-b'>
                <div className='flex flex-wrap -m-8 text-center sm:text-left'>
                  <div className='w-full sm:w-1/2 lg:w-2/12 p-8'>
                    <h3 className='mb-6 font-semibold leading-normal'>Entreprise</h3>
                    <ul>
                      <li className='mb-3.5'>
                        <a
                            className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://docs.prospectis.immo/company/about-us'
                          >
                            Qui sommes-nous ?
                          </a>
                        </li>
                        <li className='mb-3.5'>
                          <a
                              className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://docs.prospectis.immo/company/careers'
                          >
                            Carrières
                          </a>
                        </li>
                        <li className='mb-3.5'>
                          <a
                              className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://docs.prospectis.immo/company/press'
                          >
                            Presse
                          </a>
                        </li>
                        <li>
                          <a
                              className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://docs.prospectis.immo/blog'
                          >
                            Blog
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-full sm:w-1/2 lg:w-2/12 p-8'>
                      <h3 className='mb-6 font-semibold leading-normal'>Prospectis Pro</h3>
                      <ul>
                        <li className='mb-3.5'>
                          <a
                              className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://app.prospectis.immo/map/onboarding/signin'
                          >
                            Connexion
                          </a>
                        </li>
                        <li className='mb-3.5'>
                          <a
                              className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://manager.prospectis.immo/onboarding/signup'
                          >
                            Inscription
                          </a>
                        </li>
                        <li className={'mb-3.5'}>
                          <a
                              className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://docs.prospectis.immo'
                          >
                            Documentation
                          </a>
                        </li>
                        <li>
                          <a
                              className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://docs.prospectis.immo'
                          >
                            Support
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-full sm:w-1/2 lg:w-3/12 p-8'>
                      <h3 className='mb-6 font-semibold leading-normal'>Juridique</h3>
                      <ul>
                        <li className='mb-3.5'>
                          <a
                              className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://docs.prospectis.immo/legal/use-terms'
                          >
                            Conditions générales d'utilisation
                          </a>
                        </li>
                        <li className='mb-3.5'>
                          <a
                              className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://docs.prospectis.immo/legal/sale-terms'
                          >
                            Conditions générales de vente
                          </a>
                        </li>
                        <li className='mb-3.5'>
                          <a
                              className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://docs.prospectis.immo/legal/privacy'
                          >
                            Politique de confidentialité
                          </a>
                        </li>
                        <li>
                          <a
                              className='text-gray-600 hover:text-gray-700 font-medium leading-relaxed'
                              href='https://docs.prospectis.immo/legal/notice'
                          >
                            Mentions légales
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='sm:w-1/2 lg:w-5/12 p-8'>
                      <div className=''>
                        <h3 className='mb-6 font-semibold leading-normal'>
                          Demandez à être rappelé
                        </h3>
                        <p className='mb-5 font-sans text-gray-600 leading-relaxed'>
                          Laissez-nous votre téléphone et nous vous rappelons dans les plus brefs délais.
                        </p>
                        <a
                            className='inline-flex items-center justify-center text-center rounded-xl focus-within:ring focus-within:ring-fuschia-300 py-4 px-8 w-full text-white font-semibold border-fuschia-700 focus:ring focus:ring-fuschia-300 bg-fuschia-600 hover:bg-fuschia-700 transition ease-in-out duration-200'
                            type='button'
                            target={'_blank'}
                            href={"https://docs.google.com/forms/d/e/1FAIpQLScuFYp8ZaDdzUNqHbb58NnFSoW6eM0s1RGWKznh6yMLunTBEg/viewform"}
                        >
                          <svg
                              className='mr-2.5'
                              width={19}
                              height={18}
                              viewBox='0 0 19 18'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                                d='M2.75 3.75C2.75 2.92157 3.42157 2.25 4.25 2.25H6.70943C7.03225 2.25 7.31886 2.45657 7.42094 2.76283L8.5443 6.13291C8.66233 6.48699 8.50203 6.87398 8.1682 7.0409L6.47525 7.88737C7.30194 9.72091 8.77909 11.1981 10.6126 12.0247L11.4591 10.3318C11.626 9.99796 12.013 9.83767 12.3671 9.9557L15.7372 11.0791C16.0434 11.1811 16.25 11.4677 16.25 11.7906V14.25C16.25 15.0784 15.5784 15.75 14.75 15.75H14C7.7868 15.75 2.75 10.7132 2.75 4.5V3.75Z'
                                stroke='white'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                          </svg>
                          Demandez à être rappelé
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              <div className='flex flex-wrap justify-between items-center py-6 -m-4'>
                <div className='w-auto p-4'>
                  <a href='#'>
                    <img src='images/logo.png' alt='' style={{'height': 40}}/>
                  </a>
                </div>
                <div className='w-auto p-4'>
                  <p className='text-sm text-gray-600 font-medium'>
                    © Copyright 2024 Prospectis - Tous les droits sont réservés
                  </p>
                </div>
                <div className='w-auto p-4'>
                  <a
                      className='text-blueGray-400 hover:text-blueGray-500'
                      target={'_blank'}
                      href='https://www.linkedin.com/company/prospectis-immo/'
                  >
                    <svg
                        width={16}
                        height={15}
                        viewBox='0 0 16 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                          d='M3.67326 14.8544H0.564368L0.564368 5.46737H3.67326L3.67326 14.8544ZM2.11694 4.18691C1.12295 4.18691 0.316406 3.41461 0.316406 2.48264C0.316406 2.0349 0.506105 1.6055 0.84377 1.2889C1.18144 0.972297 1.63941 0.794434 2.11694 0.794434C2.59447 0.794434 3.05244 0.972297 3.39011 1.2889C3.72778 1.6055 3.91747 2.0349 3.91747 2.48264C3.91747 3.41461 3.11093 4.18691 2.11694 4.18691ZM15.3087 14.8544H12.2068V10.2849C12.2068 9.19579 12.1832 7.79933 10.5905 7.79933C8.97418 7.79933 8.72622 8.98237 8.72622 10.2066V14.8544L5.62054 14.8544L5.62054 5.46737H8.60197V6.74784H8.64535C9.0604 6.01019 10.0742 5.23187 11.5866 5.23187C14.733 5.23187 15.3114 7.17466 15.3114 9.69793V14.8544H15.3087Z'
                          fill='currentColor'
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </>
      </React.Fragment>
  );
}

